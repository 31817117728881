const LEFT_CLICK = 0;
const MIDDLE_CLICK = 1;
const RIGHT_CLICK = 2;

const initialConnection = {
  isConnecting: false,
  nodeId: 0
};

const initialNodeData = {
  nextId: 1,
  selectedId: 0,
  x: 0,
  y: 0
};

const colors = [
  '#c62828',
  '#ad1457',
  '#6a1b9a',
  '#4527a0',
  '#283593',
  '#1565c0',
  '#0277bd',
  '#00838f',
  '#00838f',
  '#2e7d32',
  '#255d00',
  '#6c6f00',
  '#bc5100',
  '#c43e00',
  '#ac1900',
  '#870000',
  '#3e2723',
  '#212121',
  '#263238'
];

const initialCtxMenu = {
  visible: false,
  x: 0,
  y: 0
};

const initialCtxMenuButtons = (stage) => {
  const contextMenu = [
    {
      id: 1,
      label: `Create ${stage}`,
      color: 'primary'
    },
    {
      id: 2,
      label: 'Close',
      color: 'secondary'
    }
  ];
  return contextMenu;
};

const defaultNodes = [
  {
    text: 'A', x: 100, y: 100
  },
  {
    text: 'B', x: 300, y: 100
  },
  {
    text: 'C', x: 300, y: 300
  },
  {
    text: 'D', x: 100, y: 300
  },
  {
    text: 'E', x: 600, y: 200
  },
  {
    text: 'F', x: 1000, y: 200
  },
  {
    text: 'G', x: 800, y: 400
  },
  {
    text: 'H', x: 600, y: 600
  },
  {
    text: 'I', x: 800, y: 600
  },
  {
    text: 'J', x: 1000, y: 600
  }
];

const defaultRoutes = [
  ['A', 'B', 5],
  ['B', 'C', 5],
  ['C', 'D', 7],
  ['A', 'D', 15],
  ['E', 'F', 5],
  ['F', 'G', 5],
  ['G', 'H', 10],
  ['H', 'I', 19],
  ['I', 'J', 5],
  ['G', 'J', 20]
];


export {
  LEFT_CLICK,
  MIDDLE_CLICK,
  RIGHT_CLICK,
  colors,
  initialNodeData,
  initialCtxMenu,
  initialCtxMenuButtons,
  initialConnection,
  defaultRoutes,
  defaultNodes
};
