export default class Graph {
  // Defining vertex array and connection list
  constructor(numberOfVertices = 0) {
    this.numberOfVertices = numberOfVertices;
    this.connectionList = new Map();
    this.connections = [];
  }

  // restore graph to initial values
  clear() {
    this.numberOfVertices = 0;
    this.connectionList = new Map();
    this.connections = [];
  }

  // Add new vertex to the graph
  addVertex(v) {
    this.numberOfVertices += 1;
    this.connectionList.set(v, []);
  }

  // Add edge between two vertices
  addEdge(v, w, weight) {
    if (!this.isConnected(v, w)){
      this.connectionList.get(v).push({ point: w, weight: weight });
      this.connections.push({ route: v + w, weight: weight });
      return true;
    }
    return false;
  }

  // check vertices are already connected
  // and make sure that vertices are not bidirectional
  isConnected(v, w) {
    return this.connections.filter(conn => {
      return conn.route === v + w || conn.route === w + v;
    }).length > 0;
  }

  // find route with cheapest weight
  findCheapestRoute(v, w) {
    return this.runAlgo(v, w, []);
  }

  // recursive algorithm to get cheapest route
  runAlgo(current, destination, visited_stations) {
    /*
    if the `current` station we are in is destination, then
    return 0 as a sign that we reached destination and visited stations
    */
    if (current === destination) {
      return [0, visited_stations];
    }

    /*
    if we already visited current station,
    return -1 as a sign that there is not need to continue
    */
    if (visited_stations.includes(current)) {
      return [-1, visited_stations];
    }

    // variable that will store minimum time taken to reach destination
    let min_time_taken = Infinity;
    // variable that will state whether there is route to destination or not
    let path_exists = false;
    // list of visited stations that will take minimum time
    let min_path_traveled = [];

    // To make sure, we don't visit station one more time
    visited_stations.push(current);

    // make sure that map has given key
    if (this.connectionList.has(current)) {
      // iterate each station
      this.connectionList.get(current).forEach(station => {
        // run recursion
        const [time_taken, path_traveled] = this.runAlgo(station.point, destination, visited_stations);

        // if we reached destination, time_taken will not be negative
        if (time_taken >= 0) {
          path_exists = true;  // since we are in given condition, then route exists
          // temporary variable to hold total time of the route
          const total_time_taken = time_taken + parseInt(station.weight);

          // if we have the same minimum time, try to find shortest route
          if (total_time_taken === min_time_taken && path_traveled.length < min_path_traveled.length) {
            min_path_traveled = [...path_traveled];
          }
          // if we can reach destination faster, update both time and path related variables
          else if (total_time_taken < min_time_taken) {
            min_time_taken = total_time_taken;
            min_path_traveled = [...path_traveled];
          }
        }
      });
    }

    /*
    remove `current` station for future usage in recursion. Otherwise,
    other parent recursion will use given `visited_stations` with all routes traveled
    instead of the one with parent routes
    */
    visited_stations.pop();

    // return result based on the condition that path exist or not
    return path_exists ? [min_time_taken, min_path_traveled] : [-1, []];
  }
}
