import React from 'react';
import PropTypes from 'prop-types';
import Konva from 'konva';
import { Button, Box } from '@material-ui/core';
import {
  Arrow, Circle, Layer, Stage, Group, Text, Rect
} from 'react-konva';

import { Node } from '../../models';
import { getRandomColor, findPoints } from '../../utils/functions';
import {
  initialCtxMenu, initialCtxMenuButtons, LEFT_CLICK, RIGHT_CLICK, initialConnection
} from '../../utils/constants';

function KonvaStage({
  graph, nodes, setNodes, weight, node, setNode
}) {
  const nodeLayerRef = React.useRef(null);

  const [ctxMenuStage, setCtxMenuStage] = React.useState(initialCtxMenu);
  const [ctxMenuNode, setCtxMenuNode] = React.useState(initialCtxMenu);

  const menuStage = initialCtxMenuButtons('Node');
  const menuNode = initialCtxMenuButtons('Connection');

  const [connection, setConnection] = React.useState(initialConnection);
  const [graphConnections, setGraphConnections] = React.useState([]);

  // for each change in nodes, update graph connections
  React.useEffect(() => {
    if (nodes.length > 0){
      setGraphConnections(getConnections());
    }
  }, [nodes]);

  // Handling the context menu click for Stage, which renders 2 buttons "Create Node", "Close"
  const handleContextMenuStage = (ev) => {
    ev.evt.preventDefault();

    setNode({
      ...node,
      x: ev.evt.layerX,
      y: ev.evt.layerY
    });

    setCtxMenuStage({
      visible: true,
      x: ev.evt.layerX,
      y: ev.evt.layerY
    });
  };

  // Handling the context menu click for Node, which renders 2 buttons "Create Connection", "Close"
  const handleContextMenuNode = (ev, nodeId) => {
    ev.evt.preventDefault();

    setNode({
      ...node,
      selectedId: nodeId,
      x: ev.evt.layerX,
      y: ev.evt.layerY
    });

    setCtxMenuNode({
      visible: true,
      x: ev.evt.layerX + 15,
      y: ev.evt.layerY - 15
    });
  };

  // close menu that was opened in the stage
  const closeCtxMenu = () => {
    setCtxMenuStage(initialCtxMenu);
    setCtxMenuNode(initialCtxMenu);
  };

  // if we click on the stage with left button of the mouse, close context menu
  const handleStageClick = (ev) => {
    ev.evt.preventDefault();
    if (ev.evt.button === LEFT_CLICK) {
      closeCtxMenu();
    }
  };

  // create new node
  const createNode = (ev) => {
    ev.preventDefault();
    const id = node.nextId;

    // create new node usong Node class
    const newNode = new Node({
      id: id,
      x: node.x + 10,
      y: node.y + 10,
      text: String.fromCharCode(id + 64),
      color: getRandomColor()
    });

    // add new node into array of nodes
    setNodes([...nodes, newNode]);

    // add new node into graph
    graph.addVertex(String.fromCharCode(id + 64));

    // update next id
    setNode({ ...node, nextId: id + 1 });
  };

  // when we want to connect to nodes
  const handleClickNode = (ev, nodeId) => {
    ev.evt.preventDefault();
    // make sure that we left clicked and boolean variable is true
    if (ev.evt.button === LEFT_CLICK && connection.isConnecting){
      // generate names from node ids
      const v = String.fromCharCode(node.selectedId + 64);
      const w = String.fromCharCode(nodeId + 64);

      // add edge into the graph between v and w, with random weight
      graph.addEdge(v, w, weight);
      // update graph
      setGraphConnections(getConnections());
      // reinitialze connection state
      setConnection(initialConnection);

    } else if (ev.evt.button === RIGHT_CLICK && connection.isConnecting) {
      setConnection(initialConnection);
    }
  };

  // Dragging the node
  const handleDragStart = (ev) => {
    // Closing all context menus when dragging
    closeCtxMenu();
    ev.target.setAttrs({
      scaleX: 1.1,
      scaleY: 1.1
    });
  };

  // Dragging the node
  const handleDragEnd = (ev) => {
    // Closing all context menus when dragging
    closeCtxMenu();
    ev.target.to({
      duration: 0.5,
      easing: Konva.Easings.ElasticEaseOut,
      scaleX: 1,
      scaleY: 1
    });
    // update graph connection after node drag ended
    setGraphConnections(getConnections());
  };

  // on right click on empty space
  const handleContextMenuClickStage = (ev, id) => {
    ev.preventDefault();
    // if we clicked on creation of new node
    if (id === 1){
      createNode(ev);
    }
    // whether we clicked on create node or close, in both cases we need to close context menu
    closeCtxMenu();
  };

  // on right click on node
  const handleContextMenuClickNode = (ev, id) => {
    ev.preventDefault();
    // if we clicked on creation of new connection
    if (id === 1){
      setConnection({
        isConnecting: true,
        nodeId: node.selectedId
      });
    }
    // whether we clicked on create connection or close, in both cases we need to close context menu
    closeCtxMenu();
  };

  // render context menu buttons
  const renderMenu = (stage, items, isStage) => {
    let boxStyle = {
      position: 'absolute',
      top: stage.y,
      left: stage.x
    };

    // Styles for every button inside CTX Menu
    let buttonStyle = { margin: 3 };

    return (
      <Box display='flex' flexDirection='column' style={boxStyle}>
        {items.map((item, index) => {
          return (
            <Button variant={'contained'}
              onClick={
                isStage
                  ? (e) => handleContextMenuClickStage(e, item.id)
                  : (e) => handleContextMenuClickNode(e, item.id)
              }
              color={item.color}
              style={buttonStyle}
              key={index}
            >
              {item.label}
            </Button>
          );
        })}
      </Box>
    );
  };

  // update arrows
  const getConnections = () => {
    let connections = [];

    graph.connections.forEach(conn => {
      // Finding points for route (eg. route = "AB", points returned
      // [x1=100, y1=100, x2=200, y2=200])
      // and saving points into variables for easy understanding
      const [x1, y1, x2, y2] = findPoints(conn.route, nodeLayerRef);

      // Get middle of two points
      const x3 = (x1 + x2) / 2;
      const y3 = (y1 + y2) / 2;

      // Adding all the variables to connections
      connections.push({
        points: [x1, y1, x2, y2],
        weight: conn.weight,
        textPoint: [x3, y3]
      });
    });

    return connections;
  };

  return (
    <div>
      <Stage width={window.innerWidth}
        height={window.innerHeight}
        onContextMenu={handleContextMenuStage}
        onClick={handleStageClick}
      >
        <Layer>
          {/* Creating arrows */}
          {graphConnections.map((conn, id) => {
            return (
              <Group key={id}>
                <Arrow
                  points={conn.points}
                  pointerLength={50}
                  pointerWidth={10}
                  fill={'black'}
                  stroke={'red'}
                  strokeWidth={4}
                  opacity={0.5}
                />
                {/* Rectangle that holds text inside it */}
                <Rect
                  width={20}
                  height={20}
                  x={conn.textPoint[0] - 10}
                  y={conn.textPoint[1] - 10}
                  fill={'black'}
                  opacity={1}
                  rotation={0}
                />
                {/* Text element that shows weight of arrow (connection) */}
                <Text
                  x={conn.textPoint[0] - 15}
                  y={conn.textPoint[1] - 13.5}
                  width={30}
                  height={30}
                  fontSize={15}
                  align={'center'}
                  verticalAlign={'middle'}
                  fill={'white'}
                  text={conn.weight}/>
              </Group>
            );
          })}
        </Layer>
        <Layer ref={nodeLayerRef}>
          {nodes.map(node => {
            return (
              <Group
                key={node.id}
                x={node.x}
                y={node.y}
                text={node.text}
                onClick={(e) => handleClickNode(e, node.id)}
                onContextMenu={(e) => handleContextMenuNode(e, node.id)}
                draggable
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
              >
                <Circle
                  radius={30}
                  fill={node.color}
                  opacity={0.9}
                  rotation={0}
                  shadowEnabled={connection.nodeId === node.id && connection.isConnecting}
                  shadowColor={node.color}
                  shadowBlur={3}
                  shadowWidth={2}
                />
                <Text
                  text={node.text}
                  x={-30}
                  y={-27.5}
                  width={60}
                  height={60}
                  fontSize={30}
                  fill={connection.nodeId === node.id && connection.isConnecting ? 'red' : 'white'}
                  align={'center'}
                  verticalAlign={'middle'}
                />
              </Group>
            );
          })}
        </Layer>
      </Stage>
      <div>
        {ctxMenuStage.visible && !ctxMenuNode.visible ? renderMenu(ctxMenuStage, menuStage, true) : null}
      </div>
      <div>
        {ctxMenuNode.visible ? renderMenu(ctxMenuNode, menuNode, false) : null}
      </div>
    </div>
  );
}

KonvaStage.propTypes = {
  graph: PropTypes.any,
  nodes: PropTypes.array,
  setNodes: PropTypes.func,
  node: PropTypes.object,
  setNode: PropTypes.func,
  weight: PropTypes.number
};

export default KonvaStage;
