import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Graph } from './models';
import { Sidebar } from './components/sidebar';
import { KonvaStage } from './components/konva-stage';

import { initialNodeData } from './utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row'
  },
  sidebar: {
    width: '300px'
  },
  konva: {
    width: window.innerWidth - 300
  }
}));

const graph = new Graph();

const App = () => {
  const classes = useStyles();
  const [weight, setWeight] = React.useState(5);
  const [nodes, setNodes] = React.useState([]);
  const [node, setNode] = React.useState(initialNodeData);

  return (
    <div className={classes.root}>
      <div className={classes.konva}>
        <KonvaStage
          graph={graph}
          nodes={nodes}
          setNodes={setNodes}
          node={node}
          setNode={setNode}
          weight={weight}
        />
      </div>
      <div className={classes.sidebar}>
        <Sidebar
          graph={graph}
          nodes={nodes}
          setNodes={setNodes}
          node={node}
          setNode={setNode}
          weight={weight}
          setWeight={setWeight}
        />
      </div>
    </div>
  );
};

export default App;
