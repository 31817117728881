import {
  colors, defaultRoutes, defaultNodes
} from './constants';

const getRandomColor = () => {
  // generate random color from already defined ones in constants.js
  return colors[Math.floor(Math.random() * colors.length)];
};

// find position of two points that we want to connect
const findPoints = (text, nodeLayer) => {
  let attrs1 = nodeLayer.current.children
    .filter(group => group.attrs.text === text[0])[0].attrs;
  let attrs2 = nodeLayer.current.children
    .filter(group => group.attrs.text === text[1])[0].attrs;
  return [attrs1.x, attrs1.y, attrs2.x, attrs2.y];
};

// get default routes in the format {start: 'A', end: 'B', weight: 5}
const getDefaultRoutes = () => {
  let routes = [];

  defaultRoutes.forEach(route => {
    const [start, end, weight] = route;
    routes.push({
      start: start, end: end, weight: weight
    });
  });

  return routes;
};

// return data from constants.js
const getDefaultNodes = () => defaultNodes;

export {
  getRandomColor,
  findPoints,
  getDefaultRoutes,
  getDefaultNodes
};
