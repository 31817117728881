import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, FormControl, InputLabel, Select, MenuItem, Button, TextField
} from '@material-ui/core';

import { Node } from '../../models';
import { initialNodeData } from '../../utils/constants';
import {
  getDefaultRoutes, getDefaultNodes, getRandomColor
} from '../../utils/functions';


const useStyles = makeStyles((theme) => ({
  box: {
    border: '3px solid lightGrey',
    borderRadius: '30px',
    padding: '1.5em',
    marginBottom: '5px',
    display: 'flex',
    flexDirection: 'column'
  },
  textField: {
    marginLeft: '50px',
    marginRight: '50px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    height: '100%'
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: '10px'
  }
}));

function Sidebar({
  graph, nodes, setNodes, weight, setWeight, node, setNode
}) {

  const classes = useStyles();
  const [routeStart, setRouteStart] = React.useState('');
  const [routeEnd, setRouteEnd] = React.useState('');

  // on every nodes update
  React.useEffect(() => {
    // if we created new node, assign start node
    if (nodes.length > 0 && routeStart === '') {
      setRouteStart(nodes[0].text);
    }
    // if we created second node, assign end node
    if (nodes.length > 1 && routeEnd === '') {
      setRouteEnd(nodes[1].text);
    }
  }, [nodes]);

  // on starting route change
  const handleRouteStartChange = (ev) => {
    setRouteStart(ev.target.value);
  };

  // on ending route change
  const handleRouteEndChange = (ev) => {
    setRouteEnd(ev.target.value);
  };

  // running dfs algorithm to find cheapest route
  const handleCheckCheapestRoute = (ev) => {
    const [time_taken, path_traveled] = graph.findCheapestRoute(routeStart, routeEnd);
    // make sure that route exists
    if (time_taken >= 0){
      alert(
        `Your trip from ${routeStart} to ${routeEnd} includes ` +
        `${path_traveled.length - 1} stops and will take ${time_taken} minutes`
      );
    }else {
      alert(`No routes from ${routeStart} to ${routeEnd}`);
    }
  };

  // on change of route weight change
  const handleWeightChange = (ev) => {
    setWeight(ev.target.value);
  };

  // generate default stations
  const handleInitialData = (ev) => {
    // clear graph and remove all previos nodes
    graph.clear();
    // get default routes and nodes from helper functions
    const defaultRoutes = getDefaultRoutes();
    const defaultNodes = getDefaultNodes();
    let tempNodes = [];
    let id = 1;

    // reinitialize node
    setNode(initialNodeData);

    // iterate all default nodes
    defaultNodes.forEach(defNode => {
      // create new node
      const newNode = new Node({
        id: id,
        x: defNode.x,
        y: defNode.y,
        text: defNode.text,
        color: getRandomColor()
      });

      // add new vertex in graph
      graph.addVertex(defNode.text);

      // and add node to array of nodes
      tempNodes.push(newNode);
      // increment id to change name of the next node
      id += 1;
    });

    // iterate each route and create edge between nodes
    defaultRoutes.forEach(route => {
      graph.addEdge(route.start, route.end, route.weight);
    });

    // update parent states
    setNodes([...tempNodes]);
    setNode({ ...node, nextId: id });
  };

  return (
    <div>
      <Box id={'assignEdgeLengthBox'} className={classes.box}>
        <TextField
          label="Distance"
          className={classes.textField}
          value={weight}
          onChange={handleWeightChange}
          variant="outlined"/>
      </Box>
      <Box id={'findCheapestRouteBox'} className={classes.box}>
        <div className={classes.form}>
          <FormControl variant={'outlined'}>
            <InputLabel id='cheapRouteStartLabel'>
              Start
            </InputLabel>
            <Select
              labelId='cheapRouteStartLabel'
              id='cheapRouteStart'
              value={routeStart}
              onChange={handleRouteStartChange}
              style={{width: 75}}
            >
              {nodes.map((node, i) => (
                <MenuItem key={i} value={node.text}>
                  <em>{node.text}</em>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant={'outlined'}>
            <InputLabel id='cheapRouteEndLabel'>
              End
            </InputLabel>
            <Select
              labelId='cheapRouteEndLabel'
              id='cheapRouteEnd'
              value={routeEnd}
              onChange={handleRouteEndChange}
              style={{width: 75, marginLeft: 10}}
            >
              {nodes.map((node, i) => (
                <MenuItem key={i} value={node.text}>
                  <em>{node.text}</em>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Button
          variant={'contained'}
          className={classes.button}
          color={'primary'}
          onClick={handleCheckCheapestRoute}
        >
          Find fastest route
        </Button>
      </Box>
      <Box id={'assignEdgeLengthBox'} className={classes.box}>
        <Button
          variant={'contained'}
          className={classes.button}
          color={'primary'}
          onClick={handleInitialData}
        >
          Initialize default routes
        </Button>
      </Box>
    </div>
  );
}

Sidebar.propTypes = {
  graph: PropTypes.any,
  nodes: PropTypes.array,
  setNodes: PropTypes.func,
  node: PropTypes.object,
  setNode: PropTypes.func,
  weight: PropTypes.number,
  setWeight: PropTypes.func
};

export default Sidebar;
